/*eslint-disable */
import request from '../../Utils/curl'

let TPrepResults = {
  /**
   * tprepResultsList
   */
  async tprepResultsList (context, subjectModuleObj, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }
      if (subjectModuleObj.gide_user_id) {
        post_data.append('gide_user_id', subjectModuleObj.gide_user_id)
      }
      post_data.append('app_id', subjectModuleObj.app_id)
      return await request.curl(context, "tprep_results_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at tprepResultsList() and Exception:',err.message)
    }
  }
}

export {
  TPrepResults
}
